import clsx from 'clsx';
import * as React from 'react';
import Balancer from 'react-wrap-balancer';
import styles from '@/styles/HeroSection.module.scss';
import CTAButtons from '@/components/landing/CTAButtons';
import {CTA_HEADERS, CTA_VERSION} from "@/lib/constants";

const HeroSection: React.FC<{
  pageType?: string;
  titleTop: string;
  titleBottom?: string;
  subtitle: string;
}> = ({ pageType, titleTop, titleBottom, subtitle }) => {
  return (
    <section className={clsx(styles.hero)}>
      <div className={clsx(styles.heroContent)}>
        {pageType !== undefined && (
          <>
            <div className='mx-auto mb-[-10px] w-fit rounded-full border-[1px] border-slate-200 bg-slate-100 px-4 py-2'>
              <p>{pageType}</p>
            </div>
          </>
        )}
        <h1 className={clsx(styles.heroTextCentered)}>
          {titleTop}
          {titleBottom && (
            <>
              <br /> {titleBottom}
            </>
          )}
        </h1>
        <p className={clsx(styles.heroSubtitle)}>
          <Balancer>{subtitle}</Balancer>
        </p>
        <CTAButtons ctaSection="Hero" ctaVersion={CTA_VERSION} ctaHeader={CTA_HEADERS.getStartedSection[0]} />
      </div>
    </section>
  );
};

export default HeroSection;
