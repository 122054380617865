import Link from 'next/link';
import * as React from 'react';

import MainButton from '@/components/buttons/MainButton';
import {usePathname} from "next/navigation";

interface CTAProps {
  ctaVersion: number;
  ctaSection: string;
  ctaHeader: string;
}

const CTAButtons: React.FC<CTAProps> = (props) => {
  const pathname = usePathname();

  const mainCTALabel = "Try free";
  const secondaryCTALabel = "Request demo";

  const trackClick = (buttonLabel: string) => {
    console.log('CTA Clicked');
    if (window.analytics) {
      console.log('window.analytics exists');

      window.analytics.track('CTA Clicked', {
        /** page they entered the site */
        first_page: '', // TODO
        /** links we create & share */
        type: '', // TODO
        /** which page did they paste a YouTube link on? */
        cta_version: props.ctaVersion,
        /** which page? */
        cta_page: pathname,
        /** which section of the page? */
        cta_section: props.ctaSection,
        /** Copy of primary or secondary CTA button */
        cta_button_label: buttonLabel,
        /** Header of the CTA at the time*/
        cta_header: props.ctaHeader,
        // TODO: utm params
        utm_source: '',
        utm_campaign: '',
      });
    }
  }

  return (
    <div className='flex flex-row items-center justify-center space-x-2 md:space-x-4'>
      <MainButton
        onClick={() => trackClick(mainCTALabel)}
        className='block h-[66px] w-full px-[60px] text-lg font-normal md:text-xl lg:w-auto'
        href='https://app.clip.fm/login'
      >
        {mainCTALabel}
      </MainButton>
      <Link legacyBehavior href='/get-started'>
        <button
          className='block h-[66px] w-full rounded-full border border-black bg-slate-50 px-[30px] text-lg text-black hover:bg-white md:text-xl lg:w-auto'
          onClick={() => trackClick(secondaryCTALabel)}
        >
          {secondaryCTALabel}
        </button>
      </Link>
    </div>
  );
};

export default CTAButtons;
