import clsx from 'clsx';
import { InstagramIcon, YoutubeIcon } from 'lucide-react';
import Link from 'next/link';
import * as React from 'react';

import styles from '@/styles/Footer.module.scss';

import Logo from '@/components/layout/Logo';

import {
  ABOUT_LINK,
  CAREERS_LINK,
  CONTACT_LINK,
  DISCORD_LINK,
  INSTAGRAM_LINK,
  LEGAL_TOC_LINK,
  PRIVACY_POLICY_LINK,
  TIKTOK_LINK,
  YOUTUBE_LINK,
} from '@/content/links';

import { FC } from '../../../types';

const Footer: FC<{ mode?: 'dark' | 'light' }> = () => {
  return (
    <section
      className={clsx('mx-auto max-w-screen-xl py-8 md:py-16', styles.footer)}
    >
      <div className='gap-16 space-y-6 px-4 md:grid md:grid-cols-5 md:space-y-0'>
        <div className='space-y-2'>
          <Logo className='h-8' />
          <p>Turn podcasts into viral clips with one click.</p>
        </div>
        <div className='flex flex-col gap-2'>
          <h4>Resources</h4>
          <Link href='/'>Home</Link>
          <Link href='/blog'>Blog</Link>
          <Link href='/waitlist'>Waitlist</Link>
          <Link href={DISCORD_LINK}>Discord</Link>
        </div>
        <div className='flex flex-col gap-2'>
          <Link href='/compare'>
            <h4>Compare</h4>
          </Link>
          <Link key="clipfm-vs-descript" href={`/compare/clipfm-vs-descript`}>
            ClipFM vs. Descript
          </Link>
          <Link key="clipfm-vs-dumme" href={`/compare/clipfm-vs-dumme`}>
            ClipFM vs. Dumme
          </Link>
          <Link key="clipfm-vs-vidyo" href={`/compare/clipfm-vs-vidyo`}>
            ClipFM vs. Vidyo
          </Link>
          <Link key="clipfm-vs-opus-clip" href={`/compare/clipfm-vs-opus-clip`}>
            ClipFM vs. Opus Clip
          </Link>
          <Link key="clipfm-vs-zubtitle" href={`/compare/clipfm-vs-zubtitle`}>
            ClipFM vs. Zubtitle
          </Link>
          <Link key="clipfm-vs-munch" href={`/compare/clipfm-vs-munch`}>
            ClipFM vs. Munch
          </Link>
          <Link key="clipfm-vs-descript" href={`/compare/ai-powered-clip-making-tools`}>
            Compare all
          </Link>
        </div>
        <div className='flex flex-col gap-2'>
          <h4>Company</h4>
          <Link href={ABOUT_LINK}>About</Link>
          <Link href={CAREERS_LINK}>Careers</Link>
          <Link href={CONTACT_LINK}>Contact</Link>
          <Link href={INSTAGRAM_LINK}>
            Instagram <InstagramIcon className='inline h-4' />
          </Link>
          <Link href={YOUTUBE_LINK}>
            YouTube <YoutubeIcon className='inline h-4' />
          </Link>
          <Link href={TIKTOK_LINK}>TikTok</Link>
        </div>
        <div className='flex flex-col gap-2'>
          <h4>Legal</h4>
          <Link href={LEGAL_TOC_LINK}>Terms of Service</Link>
          <Link href={PRIVACY_POLICY_LINK}>Privacy Policy</Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;
