import clsx from 'clsx';
import * as React from 'react';

import Footer from '@/components/layout/Footer';
import Nav from '@/components/layout/Nav';

import { FC } from '../../../types';

const Layout: FC<{ mode?: 'dark' | 'light'; className?: string }> = (props) => {
  return (
    <div className='bg-slate-50'>
      <Nav mode={props.mode} />
      <main className={clsx('min-h-[70vh]', props.className)}>
        {props.children}
      </main>
      <Footer mode={props.mode} />
    </div>
  );
};

export default Layout;
