import Link from 'next/link';
import { FC } from 'types';

const Logo: FC<{ className?: string }> = ({ className }) => (
  <Link legacyBehavior href='/'>
    <a
      className='cursor-pointer opacity-95 hover:opacity-100'
      style={{ fontFamily: 'system-ui, "Segoe UI"' }}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src='/theme/logo@3x.png'
        alt='clip.fm'
        className={className}
        title='clip.fm'
      />
    </a>
  </Link>
);

export default Logo;
