'use client';

import * as Popover from '@radix-ui/react-popover';
import { useState } from 'react';
import * as React from 'react';

import PopoverNavItem, { MenuItem } from '@/components/layout/PopoverNavItem';

export default function PopoverNav({
  menuItems,
  align = 'start',
  children,
}: {
  menuItems: MenuItem[];
  align?: 'center' | 'start' | 'end';
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
      <Popover.Trigger
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className='inline-flex hover:cursor-pointer'
        asChild
      >
        {children}
      </Popover.Trigger>
      <Popover.Content
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        align={align}
        className='z-20 focus:outline-none'
      >
        <div className='pt-3 md:pt-2'>
          <div className='w-screen bg-slate-50 shadow-lg md:w-56 md:rounded-md md:bg-white md:p-2'>
            {menuItems.map((item, index) => (
              <PopoverNavItem key={index} item={item} />
            ))}
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
