import { ExternalLinkIcon } from 'lucide-react';
import Link from 'next/link';
import { FC } from 'types';

export interface MenuItem {
  name: string;
  href: string;
}

const PopoverNavItem: FC<{ item: MenuItem }> = ({ item }) => (
  <Link legacyBehavior href={item.href}>
    <a className='block w-full rounded-md px-4 py-3 text-left text-lg transition-all duration-75 hover:cursor-pointer active:bg-slate-200 md:p-2 md:hover:bg-slate-100'>
      {item.name}
      {item.href.startsWith('https://') && (
        <ExternalLinkIcon className='float-right mt-2 h-3.5 text-gray-400' />
      )}
    </a>
  </Link>
);

export default PopoverNavItem;
