import clsx from 'clsx';
import { useAtom } from 'jotai';
import { ChevronDownIcon, MenuIcon } from 'lucide-react';
import Link from 'next/link';
import * as React from 'react';

import styles from './Nav.module.scss';

import MainButton from '@/components/buttons/MainButton';
import Logo from '@/components/layout/Logo';
import PopoverNav from '@/components/layout/PopoverNav';
import { MenuItem } from '@/components/layout/PopoverNavItem';

import { DISCORD_LINK } from '@/content/links';
import { waitlistModalAtom } from '@/state';

import { FC } from '../../../types';

const NAV_ITEMS = [
  {
    name: 'Use Cases',
    items: [
      {
        name: 'For Podcasters',
        href: '/for-podcasters',
      },
      {
        name: 'For Editors',
        href: '/for-editors',
      },
      {
        name: 'For Marketers',
        href: '/for-marketers',
      },
      {
        name: 'For Agencies',
        href: '/for-agencies',
      },
      {
        name: 'For Podcast Studios',
        href: '/for-podcast-studios',
      },
    ],
  },
  {
    name: 'Pricing',
    href: '/pricing',
  },
  {
    name: 'Blog',
    href: '/blog',
  },
  {
    name: 'Company',
    items: [
      {
        name: 'About',
        href: '/about',
      },
      {
        name: 'Discord',
        href: DISCORD_LINK,
      },
      {
        name: 'Jobs',
        href: 'https://striler.notion.site/Open-Positions-491e6260cac54ebe985032ac350534fe',
      },
      {
        name: 'Support',
        href: 'https://striler.notion.site/Support-592df9d0a30346d18ddaac7308ac1125',
      },
    ],
  },
];

const MOBILE_NAV_ITEMS = NAV_ITEMS.reduce<MenuItem[]>((acc, item) => {
  if (item.href) acc.push(item);
  else acc.push(...(item.items || []));
  return acc;
}, []);

const Nav: FC<{ mode?: 'dark' | 'light' }> = () => {
  const [_, setOpen] = useAtom(waitlistModalAtom);

  return (
    <header
      className={clsx('sticky top-0 z-40 bg-slate-50 py-2.5', styles.nav)}
    >
      {/* Desktop Nav */}
      <div className={clsx('max-layout hidden items-center text-lg lg:flex')}>
        <div className='w-48'>
          <Logo className='h-7' />
        </div>
        <div className='flex flex-1 flex-row items-center justify-center'>
          {NAV_ITEMS.map((item, index) => {
            if (item.items) {
              return (
                <PopoverNav key={index} menuItems={item.items}>
                  <a className={styles.topLevelLink}>
                    {item.name}{' '}
                    <ChevronDownIcon className='ml-1.5 mt-1.5 h-4 w-4' />
                  </a>
                </PopoverNav>
              );
            }

            return (
              <Link legacyBehavior key={index} href={item.href}>
                <a className={styles.topLevelLink}>{item.name}</a>
              </Link>
            );
          })}
        </div>
        <div className='flex w-48 flex-row items-center justify-end'>
          {/* <a
            href={DISCORD_LINK}
            target='_blank'
            rel='noreferrer'
            className={clsx('mr-6 rounded-full')}
          >
            Discord
          </a> */}
          <MainButton
            className='float-right h-[45px] w-auto'
            href='https://app.clip.fm/login'
          >
            <span className='text-white'>Sign in</span>
          </MainButton>
        </div>
      </div>
      {/* Mobile Nav */}
      <div
        className={clsx(
          'max-layout flex items-center justify-between py-1 lg:hidden'
        )}
      >
        <Logo className='h-6' />
        <PopoverNav menuItems={MOBILE_NAV_ITEMS} align='end'>
          <a>
            <MenuIcon />
          </a>
        </PopoverNav>
      </div>
    </header>
  );
};

export default Nav;
