export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://clip.fm';

// Company
export const DISCORD_LINK = `https://discord.gg/clipfm`;
export const ABOUT_LINK =
  'https://striler.notion.site/About-Clip-fm-d875ca0fed394fb6b9f7294234b7edfd';
export const CAREERS_LINK =
  'https://striler.notion.site/Open-Positions-491e6260cac54ebe985032ac350534fe';
export const CONTACT_LINK =
  'https://striler.notion.site/Contact-592df9d0a30346d18ddaac7308ac1125';

// Socials
export const INSTAGRAM_LINK = 'https://www.instagram.com/clipdotfm';
export const YOUTUBE_LINK =
  'https://www.youtube.com/channel/UCEd-PTmqayfXeTVwX_6JP2A';
export const TIKTOK_LINK = 'https://www.tiktok.com/@clip.fm';

// Legal
export const LEGAL_TOC_LINK =
  'https://striler.notion.site/Terms-and-Conditions-9170ade6377545c0a94f41f2a9235ffa';
export const PRIVACY_POLICY_LINK =
  'https://striler.notion.site/Privacy-Policy-b5c307c0f07c4a93ab07e02ddf76b742';
